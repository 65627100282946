import produce from "immer";
import { FormEvent, ReactNode, useState } from "react";
import { MonitorInfoForm, TerminalForm } from "../gateways/cms/terminal";
import { MonitorEditForm } from "./MonitorEditForm";
import { SelectDeliveryGroup } from "./SelectDeliveryGroup";
import { SelectDisplayModel } from "./SelectDisplayModel";

import { styled } from "@mui/system";
import iconSelectDown from "../assets/icons/icon_arrow_down.svg";
import iconCheckboxOff from "../assets/icons/icon_checkbox_off.svg"
import iconCheckboxOn from "../assets/icons/icon_checkbox_on.svg"

export interface Props {
    children: ReactNode;
    organizationId: string;
    data?: TerminalForm;
    onSubmit: (data: TerminalForm) => void;
}

// style
const TokenItem = styled("li")`
    display: flex;
    align-items: center;
    label{
        margin-bottom: 2px;
        transform: translate(0px, 4px); 
    }
    input[type="checkbox"] {
        width: 0;
        height: 0;
        margin: 0 calc(24px + 1px) 0 0;
        margin-bottom: 28px;

        &::before {
            content: "";
            display: block;
            width: 20px;
            height: 20px;

            background-color: transparent;
            background-attachment: scroll;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url(${iconCheckboxOff});
            transform: translate(0, 6px);
        }
        &:checked::before {
            background-image: url(${iconCheckboxOn});
        }
    }
    label{
        margin-bottom: 0px;
    }
`;
const InputList = styled("ul")`
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    margin: 0 -24px 0 0;

    & > li{
        margin-right: 24px;
        margin-bottom: 12px;
        width: calc((100% - 24px - 24px - 24px) / 3);

        label{
            width: 100%;
            margin-bottom: 4px;
        }
        input[type="text"],
        select{
            height: 32px;
            padding: 0 0.5em;
            width: 100%;
        }
    }

    // モニター以外
    &:nth-of-type(1){
        margin-bottom: 24px;
        
        & > li{
            &:nth-of-type(1){ width: calc((100% - 24px - 24px - 24px) / 3 * 2 + 24px); }
            &:nth-of-type(3){ transform: translate(0px, 6px); }
            &:nth-of-type(4){ width: calc((100% - 24px - 24px - 24px) / 3 * 2 + 24px); 
                TokenItem,
            }
            &:nth-of-type(9){ width: calc((100% - 24px - 24px - 319px) / 3 * 2 + 24px);}
            &:nth-of-type(11){ width: calc((100% - 24px - 24px - 181px) / 3 * 2 + 24px); 
                input[type="tel"],
                select{
                    height: 32px;
                    padding: 0 0.5em;
                    width: 65%;
                }
            }
        }
    }
`;

export function TerminalEditForm({ children, organizationId, data, onSubmit }: Props) {
    const [name, setName] = useState(data?.name ?? "");
    const [deliveryGroupId, setDeliveryGroupId] = useState(String(data?.deliveryGroupId ?? ""));
    //const [loginId, setLoginId] = useState(data?.loginId ?? "");
    const [manageId, setManageId] = useState(data?.manageId ?? "");
    const [token, setToken] = useState(data?.token ?? "");
    const [isToken, setIsToken] = useState(data?.isToken ?? false);
    //const [password, setPassword] = useState("");
    const [terminalIp, setTerminalIp] = useState(data?.terminalIp);
    const [terminalMask, setTerminalMask] = useState(data?.terminalMask);
    const [terminalGw, setTerminalGw] = useState(data?.terminalGw);
    const [terminalDns, setTerminalDns] = useState(data?.terminalDns);
    const [dnsFallback, setDnsFallback] = useState(data?.dnsFallback ?? "8.8.8.8");
    const [ntpServer, setNtpServer] = useState(data?.ntpServer);
    const [ntpConfirmTime, setNtpConfirmTime] = useState(data?.ntpConfirmTime);
    const [monitorModel, setMonitorModel] = useState(data?.monitorModel ?? "");
    const [direction, setDirection] = useState(data?.direction ?? 0);
    const [monitors, setMonitors] = useState<MonitorInfoForm[]>(
        [
            ...(data?.monitorInfo ?? []).map((m) => ({ ...m, enabled: true })),
            ...Array.from(Array(16 - (data?.monitorInfo ?? []).length), () => ({
                ip: "",
                enabled: false,
            })),
        ].map((m, i) => ({ ...m, label: `モニター${i + 1}` }))
    );

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit({
            id: data?.id,
            name,
            organizationId: Number(organizationId),
            deliveryGroupId: Number(deliveryGroupId),
            //loginId,
            manageId,
            token,
            isToken,
            //password,
            terminalIp,
            terminalMask,
            terminalGw,
            terminalDns,
            dnsFallback,
            ntpServer,
            ntpConfirmTime,
            monitorModel,
            direction,
            monitorInfo: monitors.filter((m) => m.enabled),
        });
    };

    const setMonitor = (index: number, data: MonitorInfoForm) => {
        setMonitors(
            produce(monitors, (newMonitors) => {
                newMonitors[index] = data;
            })
        );
    };
    const checkToken = (token: string):boolean => {
        if(token && token !== "未設定"){
            return true;
        }
        return false;
    };

    const SelectWithIcon = styled("select")`
        appearance: none;
        padding-right: 1.8em;
        border-radius: 0;
        background: transparent url(${iconSelectDown}) right center no-repeat scroll;
    `;

    return (
        <form onSubmit={handleSubmit}>
            <main>
                <InputList>
                    <li>
                        <label>端末名</label>
                        <input type="text" value={name} required onChange={(e) => setName(e.target.value)} />
                    </li>
                    <li>
                        <label htmlFor="deliveryGroup">配信グループ</label>
                        <SelectDeliveryGroup
                            organizationId={organizationId}
                            value={deliveryGroupId}
                            required
                            onChange={setDeliveryGroupId}
                        />
                    </li>
                    <li>
                        <label htmlFor="manageId">MANAGE ID</label>
                        <input type="text" value={manageId} required onChange={(e) => setManageId(e.target.value)} />
                    </li>
                    <li>
                        <TokenItem>
                            <label htmlFor="token">Token（自動設定）</label>
                            {checkToken(token)  && ( <>
                                <input type="checkbox" checked={isToken} onChange={(e) => setIsToken(e.target.checked)} />
                                <label htmlFor="isToken">トークンを再発行する</label> 
                            </>)}
                        </TokenItem>
                        <input type="text" value={token} onChange={(e) => setToken(e.target.value)} disabled />
                    </li>
                    <li>
                        <label htmlFor="terminalIp">IP</label>
                        <input type="text" pattern="[\d\.]*" value={terminalIp} required onChange={(e) => setTerminalIp(e.target.value)} />
                    </li>
                    
                    {/*
                    <li>
                        <label htmlFor="password">Password</label>
                        <input type="text" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </li>
                    */}
                    <li>
                        <label htmlFor="terminalMask">mask</label>
                        <input type="text" pattern="[\d\.]*" value={terminalMask} required onChange={(e) => setTerminalMask(e.target.value)} />
                    </li>
                    <li>
                        <label htmlFor="terminalGw">GW</label>
                        <input type="text" pattern="[\d\.]*" value={terminalGw} required onChange={(e) => setTerminalGw(e.target.value)} />
                    </li>
                    <li>
                        <label htmlFor="terminalDns">DNS</label>
                        <input type="text" pattern="[\d\.]*" value={terminalDns} required onChange={(e) => setTerminalDns(e.target.value)} />
                    </li>
                    <li>
                        <label htmlFor="terminalDns">代替DNS</label>
                        <input type="text" pattern="[\d\.]*" value={dnsFallback} required onChange={(e) => setDnsFallback(e.target.value)} />
                    </li>
                    <li>
                        <label htmlFor="ntpServer">NTPサーバー</label>
                        <input type="text" value={ntpServer} required onChange={(e) => setNtpServer(e.target.value)} />
                    </li>
                    <li>
                        <label htmlFor="ntpConfirmTime">NTPサーバー問い合わせ間隔（ミリ秒）</label>
                        <input type="tel" inputMode="numeric" pattern="[\d]*" value={ntpConfirmTime} required onChange={(e) => setNtpConfirmTime(e.target.value)} />
                    </li>
                    <li>
                        <label htmlFor="monitorModel">モニター型番</label>
                        <SelectDisplayModel value={monitorModel} required onChange={setMonitorModel} />
                    </li>
                    <li>
                        <label htmlFor="direction">向き</label>
                        <SelectWithIcon value={direction} required onChange={(e) => setDirection(Number(e.target.value))}>
                            <option value="0">0度</option>
                            <option value="1">90度</option>
                            <option value="2">180度</option>
                            <option value="3">270度</option>
                        </SelectWithIcon>
                    </li>
                </InputList>

                <InputList>
                    {monitors.map((monitor, i) => (
                        <MonitorEditForm key={monitor.label} data={monitor} onChange={(data) => setMonitor(i, data)} />
                    ))}
                </InputList>
            </main>

            {children}
        </form>
    );
}

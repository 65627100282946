import TerminalStatusCard from "./TerminalStatusCard";

import { styled } from "@mui/system";
import Box from "@material-ui/core/Box";
//import ButtonMUI from "@mui/material/Button";
import { TerminalStatusLogEntry } from "../gateways/cms/log";

// style
const GroupList = styled("section")`
    display: flex;
    justify-content: flex-start;
    padding: 0 24px;
`;
const GroupBox = styled(Box)`
    position: relative;
    width: 310px;
    height: 440px;
    margin: 0 16px 16px 0;

    & > .MuiButton-root {
        position: absolute;
        right: 8px;
        top: 8px;
        z-index: 1;
        opacity: 0;
        pointer-events: none;
    }
    &:hover > .MuiButton-root {
        opacity: 1;
        pointer-events: unset;
    }
`;
/** タスク追加 無効のためコメントアウト
const GroupButton = styled(ButtonMUI)`
    &,
    &.MuiButton-contained {
        height: 24px;
        width: 80px;
        border-radius: 2px;
        font-size: 12px;
        padding: 0;
    }
`;
** コメントアウトここまで **/

export interface Props {
    entry: TerminalStatusLogEntry;
}

export function OrganizationTerminalStatusLog({ entry }: Props) {
    return (
        <GroupList>
            {entry.organization.terminals.map((terminal) => (
                <GroupBox key={entry.organization.id}>
                    {/** タスク追加 無効のためコメントアウト
                    <GroupButton variant="contained" disableElevation href={`/tasks/create?terminalId=${terminal.id}`}>
                        タスク追加
                    </GroupButton>
                    ** コメントアウトここまで **/}

                    <TerminalStatusCard
                        title={terminal.deliveryName}
                        subheader={terminal.name}
                        message={terminal.info.message}
                        background={convertErrorLevel(terminal.info.errorLevel)}
                        image={terminal.info.screenshotUri}
                        datetime={terminal.info.createdAt}
                        organizationId={entry.organization.id}
                        terminalId={terminal.id}
                    />
                </GroupBox>
            ))}
        </GroupList>
    );
}

function convertErrorLevel(level: string): "error" | "normal" {
    switch (level) {
        case "Error":
        case "Critical":
        case "Alert":
        case "Emergency":
            return "error";
        default:
            return "normal";
    }
}

import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { useState } from "react";
import { ArticleSchemaItem } from "../gateways/cms/article";
import { Radio, radioMinimum, radioSignage } from "../gateways/cms/schema";
import { ArticleSchemaItemFileContentControl, ArticleSchemaVideoContentControl, ArticleSchemaImageContentControl, 
         ArticleSignageImageContentControl, ArticleSignageVideoContentControl, ArticleSignageURLContentControl, ArticleSignageTextContentControl }
                    from "./ArticleSchemaItemFileContentControl";
import { styled } from "@mui/system";
import iconCheckboxOff from "../assets/icons/icon_checkbox_off.svg";
import iconCheckboxOn from "../assets/icons/icon_checkbox_on.svg";
import iconRadioOff from "../assets/icons/icon_radiobutton_off.svg";
import iconRadioOn from "../assets/icons/icon_radiobutton_on.svg";
import iconSelectDown from "../assets/icons/icon_arrow_down.svg";

// style
const SchemaItem = styled("div")`
    position: relative;
    min-height: 64px;
    padding: 24px;
    margin-bottom: 24px;
    border: 1px solid #daddde;
    border-radius: 1px;

    .title {
        label {
            font-size: 15px;
        }
    }
    .info {
        label {
            font-size: 12px;
            padding-left: 24px;
            margin: 0 0 18px;
        }
    }
    .type_checkbox,
    .type_radio {
        display: flex;
        margin-bottom: 14px;

        label {
            align-items: center;
            margin: 4px 16px 0 0;
            position: relative;
        }
    }
    .type_file {
        display: grid;
        grid-template-columns: 168px 1fr;
        grid-template-rows: auto auto 1fr;
        margin-bottom: 14px;

        h3,
        label {
            padding-left: 24px;
        }
        h3 {
            grid-column: 2/3;
            grid-row: 1/2;
        }
        label {
            grid-column: 2/3;
            grid-row: 2/3;
            display: flex;
            width: 484px;

            input {
                flex: 1;
                line-height: 28px;
                padding: 0 8px;

                &::file-selector-button {
                    display: none;
                }
            }
            b {
                display: inline-block;
                width: 116px;
                height: 32px;
                border: 2px solid;
                border-radius: 2px;
                background-color: #fff;
                border: 2px solid #c9cbd3;
                font-weight: normal;
                letter-spacing: 0;
                font-size: 14px;
                text-align: center;
                line-height: 28px;
                margin-left: -1px;
                cursor: pointer;
            }
        }
        div {
            grid-column: 1/2;
            grid-row: 1/4;
            position: relative;
            width: 140px;
            height: 140px;

            &::before {
                position: absolute;
                z-index: 0;
                display: block;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                font-weight: bold;
                text-align: center;
                line-height: 140px;
                background-color: #daddde;
                color: #fff;
            }

            img {
                position: relative;
                z-index: 1;
                width: 140px;
                height: 140px;
            }

            label {
                position: relative;
                left: -18px;
                font-size: 12px;
                width: 160px;
                height: 35px;
                overflow: hidden;
                margin: 0px 0px 0 0;
            }
        }
    }
    .type_file_image {
        display: grid;
        grid-template-columns: 168px 1fr;
        grid-template-rows: auto auto 1fr;
        margin-bottom: 24px;

        h3,
        label {
            padding-left: 24px;
        }
        h3 {
            grid-column: 2/3;
            grid-row: 1/2;
        }
        label {
            grid-column: 2/3;
            grid-row: 2/3;
            display: flex;
            width: 484px;

            input {
                flex: 1;
                line-height: 28px;
                padding: 0 8px;

                &::file-selector-button {
                    display: none;
                }
            }
            b {
                display: inline-block;
                width: 116px;
                height: 32px;
                border: 2px solid;
                border-radius: 2px;
                background-color: #fff;
                border: 2px solid #c9cbd3;
                font-weight: normal;
                letter-spacing: 0;
                font-size: 14px;
                text-align: center;
                line-height: 28px;
                margin-left: -1px;
                cursor: pointer;
            }
        }
        div {
            grid-column: 1/2;
            grid-row: 1/4;
            position: relative;
            width: 140px;
            height: 140px;

            &::before {
                position: absolute;
                z-index: 0;
                display: block;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                font-weight: bold;
                text-align: center;
                line-height: 140px;
                background-color: #daddde;
                color: #fff;
            }

            img {
                position: relative;
                z-index: 1;
                width: 140px;
                height: 140px;
            }

            label {
                position: relative;
                left: -18px;
                font-size: 12px;
                width: 160px;
                height: 35px;
                overflow: hidden;
                margin: 0px 0px 0 0;
            }
        }
    }
    .type_file_video {
        display: grid;
        grid-template-columns: 168px 1fr;
        grid-template-rows: auto auto 1fr;
        margin-bottom: 24px;

        h3,
        label {
            padding-left: 24px;
        }
        h3 {
            grid-column: 2/3;
            grid-row: 1/2;
        }
        label {
            grid-column: 2/3;
            grid-row: 2/3;
            display: flex;
            width: 484px;

            input {
                flex: 1;
                line-height: 28px;
                padding: 0 8px;

                &::file-selector-button {
                    display: none;
                }
            }
            b {
                display: inline-block;
                width: 116px;
                height: 32px;
                border: 2px solid;
                border-radius: 2px;
                background-color: #fff;
                border: 2px solid #c9cbd3;
                font-weight: normal;
                letter-spacing: 0;
                font-size: 14px;
                text-align: center;
                line-height: 28px;
                margin-left: -1px;
                cursor: pointer;
            }
        }
        div {
            grid-column: 1/2;
            grid-row: 1/4;
            position: relative;
            width: 140px;
            height: 140px;

            &::before {
                position: absolute;
                z-index: 0;
                display: block;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                font-weight: bold;
                text-align: center;
                line-height: 140px;
                background-color: #daddde;
                color: #fff;
            }

            img {
                position: relative;
                z-index: 1;
                width: 140px;
                height: 140px;
            }

            label {
                position: relative;
                left: -18px;
                font-size: 12px;
                width: 160px;
                height: 35px;
                overflow: hidden;
            }
        }
    }
    .type_file_file {
        display: grid;
        grid-template-columns: 168px 1fr;
        grid-template-rows: auto auto 1fr;
        margin-bottom: 24px;

        h3,
        label {
            padding-left: 24px;
        }
        h3 {
            grid-column: 2/3;
            grid-row: 1/2;
        }
        label {
            grid-column: 2/3;
            grid-row: 2/3;
            display: flex;
            width: 484px;

            input {
                flex: 1;
                line-height: 28px;
                padding: 0 8px;

                &::file-selector-button {
                    display: none;
                }
            }
            b {
                display: inline-block;
                width: 116px;
                height: 32px;
                border: 2px solid;
                border-radius: 2px;
                background-color: #fff;
                border: 2px solid #c9cbd3;
                font-weight: normal;
                letter-spacing: 0;
                font-size: 14px;
                text-align: center;
                line-height: 28px;
                margin-left: -1px;
                cursor: pointer;
            }
        }
        div {
            grid-column: 1/2;
            grid-row: 1/4;
            position: relative;
            width: 140px;
            height: 140px;

            &::before {
                position: absolute;
                z-index: 0;
                display: block;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                font-weight: bold;
                text-align: center;
                line-height: 140px;
                background-color: #daddde;
                color: #fff;
            }

            img {
                position: relative;
                z-index: 1;
                width: 140px;
                height: 140px;
            }

            label {
                position: relative;
                left: -18px;
                font-size: 12px;
                width: 160px;
                height: 35px;
                overflow: hidden;
                margin: 0px 0px 0 0;
            }
        }
    }
    .type_signage_radio {
        display: flex;
        margin-bottom: 14px;
        label {
            align-items: center;
            margin: 4px 40px 0 0;
            position: relative;
            &:last-of-type {
                margin-left: auto;
                padding-right: 24px;
                color: #770000;
            }
        }
    }
    .type_signage_video {
        display: grid;
        grid-template-columns: 168px 1fr;
        grid-template-rows: auto auto 1fr;
        margin-bottom: 24px;

        h3 {
            grid-column: 2/3;
            grid-row: 1/2;
        }
        label {
            grid-column: 2/3;
            grid-row: 2/3;
            display: flex;
            width: 60%;

            input {
                flex: 3;
                line-height: 28px;
                padding: 0 8px;

                &::file-selector-button {
                    display: none;
                }
            }
            b {
                display: inline-block;
                flex: 1;
                padding: 0 8px;
                height: 32px;
                border: 2px solid;
                border-radius: 2px;
                background-color: #fff;
                border: 2px solid #c9cbd3;
                font-weight: normal;
                letter-spacing: 0;
                font-size: 14px;
                text-align: center;
                line-height: 28px;
                margin-left: -1px;
                cursor: pointer;
            }
        }
        b {

            grid-column: 2/3;
            grid-row: 3/3;
        }
        div {
            grid-column: 1/2;
            grid-row: 1/4;
            position: relative;
            width: 140px;
            height: 140px;

            &::before {
                position: absolute;
                z-index: 0;
                display: block;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                font-weight: bold;
                text-align: center;
                line-height: 140px;
                background-color: #daddde;
                color: #fff;
            }

            img {
                position: relative;
                z-index: 1;
                width: 140px;
                height: 140px;
            }
            label {
                position: relative;
                font-size: 12px;
                width: 160px;
                height: 35px;
                overflow: hidden;
                margin: 0px 0px 0 0;
            }
        }
    }
    .type_signage_image {
        display: grid;
        grid-template-columns: 168px 1fr;
        grid-template-rows: 55px auto 1fr;
        margin-bottom: 24px;

        select {
            grid-column: 2/3;
            grid-row: 1/3;
            border-radius: 2px;
            width: 400px;
        }
        label {
            grid-column: 2/3;
            grid-row: 3/3;
            width: 60%;
            height: 32px;

            input {
                flex: 3;
                line-height: 28px;
                padding: 0 8px;

                &::file-selector-button {
                    display: none;
                }
            }
            b {
                display: inline-block;
                flex: 1;
                padding: 0 8px;
                height: 32px;
                border: 2px solid;
                border-radius: 2px;
                background-color: #fff;
                border: 2px solid #c9cbd3;
                font-weight: normal;
                letter-spacing: 0;
                font-size: 14px;
                text-align: center;
                line-height: 28px;
                margin-left: -1px;
                cursor: pointer;
            }
        }
        b {
            grid-column: 2/3;
            grid-row: 3/3;
        }
        div {
            grid-column: 1/2;
            grid-row: 1/4;
            position: relative;
            width: 140px;
            height: 140px;

            &::before {
                position: absolute;
                z-index: 0;
                display: block;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                font-weight: bold;
                text-align: center;
                line-height: 140px;
                background-color: #daddde;
                color: #fff;
            }

            img {
                position: relative;
                z-index: 1;
                width: 140px;
                height: 140px;
            }
            label {
                position: relative;
                font-size: 12px;
                width: 160px;
                height: 35px;
                overflow: hidden;
                margin: 0px 0px 0 0;
            }
        }
    }
    .type_signage_url {
        display: grid;
        grid-template-columns: 168px 1fr;
        grid-template-rows: 55px auto 1fr;
        margin-bottom: 24px;

        select {
            grid-column: 2/3;
            grid-row: 1/2;
            border-radius: 2px;
            width: 400px;
        }
        label#image {
            grid-column: 2/3;
            grid-row: 2/3;
            width: 60%;
            height: 32px;

            input {
                flex: 3;
                line-height: 28px;
                padding: 0 8px;
                &::file-selector-button {
                    display: none;
                }
            }
            b {
                display: inline-block;
                flex: 1;
                padding: 0 8px;
                height: 32px;
                border: 2px solid;
                border-radius: 2px;
                background-color: #fff;
                border: 2px solid #c9cbd3;
                font-weight: normal;
                letter-spacing: 0;
                font-size: 14px;
                text-align: center;
                line-height: 28px;
                margin-left: -1px;
                cursor: pointer;
            }
        }
        label#url {
            grid-column: 2/3;
            grid-row: 3/4;
            width: 60%;
            height: 32px;

            input {
                flex: 3;
                line-height: 28px;
                padding: 0 8px;

                &::file-selector-button {
                    display: none;
                }
            }
        }
        div {
            grid-column: 1/2;
            grid-row: 1/4;
            position: relative;
            width: 140px;
            height: 140px;

            &::before {
                position: absolute;
                z-index: 0;
                display: block;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                font-weight: bold;
                text-align: center;
                line-height: 140px;
                background-color: #daddde;
                color: #fff;
            }

            img {
                position: relative;
                z-index: 1;
                width: 140px;
                height: 140px;
            }
            label {
                position: relative;
                font-size: 12px;
                width: 160px;
                height: 35px;
                overflow: hidden;
                margin: 0px 0px 0 0;
            }
        }
    }
    .type_signage_text {
        display: grid;
        grid-template-columns: 168px 1fr;
        grid-template-rows: 55px auto 1fr;
        margin-bottom: 24px;

        select {
            grid-column: 2/3;
            grid-row: 1/2;
            border-radius: 2px;
            width: 400px;
        }
        label#image {
            grid-column: 2/3;
            grid-row: 2/3;
            width: 61%;
            height: 32px;

            input {
                flex: 3;
                line-height: 28px;
                padding: 0 8px;
                &::file-selector-button {
                    display: none;
                }
            }
            b {
                display: inline-block;
                flex: 1;
                padding: 0 8px;
                height: 32px;
                border: 2px solid;
                border-radius: 2px;
                background-color: #fff;
                border: 2px solid #c9cbd3;
                font-weight: normal;
                letter-spacing: 0;
                font-size: 14px;
                text-align: center;
                line-height: 28px;
                margin-left: -1px;
                cursor: pointer;
            }
        }
        label#text {
            grid-column: 2/3;
            grid-row: 3/4;
            width: 61%;
            height: 32px;

            input {
                flex: 3;
                line-height: 28px;
                padding: 0 8px;

                &::file-selector-button {
                    display: none;
                }
            }
        }
        div {
            grid-column: 1/2;
            grid-row: 1/4;
            position: relative;
            width: 140px;
            height: 140px;

            &::before {
                position: absolute;
                z-index: 0;
                display: block;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                font-weight: bold;
                text-align: center;
                line-height: 140px;
                background-color: #daddde;
                color: #fff;
            }
            img {
                position: relative;
                z-index: 1;
                width: 140px;
                height: 140px;
            }
            label {
                position: relative;
                font-size: 12px;
                width: 160px;
                height: 35px;
                overflow: hidden;
                margin: 0px 0px 0 0;
            }
        }
    }
    .type_minimum_radio {
        display: flex;
        margin-bottom: 14px;
        label {
            align-items: center;
            margin: 4px 40px 0 0;
            position: relative;
            &:last-of-type {
                margin-left: auto;
                padding-right: 24px;
                color: #770000;
            }
        }
    }
    .type_minimum_video {
        display: grid;
        grid-template-columns: 168px 1fr;
        grid-template-rows: auto auto 1fr;
        margin-bottom: 24px;

        h3 {
            grid-column: 2/3;
            grid-row: 1/2;
        }
        label {
            grid-column: 2/3;
            grid-row: 2/3;
            display: flex;
            width: 484px;

            input {
                flex: 3;
                line-height: 28px;
                padding: 0 8px;

                &::file-selector-button {
                    display: none;
                }
            }
            b {
                display: inline-block;
                flex: 1;
                padding: 0 8px;
                height: 32px;
                border: 2px solid;
                border-radius: 2px;
                background-color: #fff;
                border: 2px solid #c9cbd3;
                font-weight: normal;
                letter-spacing: 0;
                font-size: 14px;
                text-align: center;
                line-height: 28px;
                margin-left: -1px;
                cursor: pointer;
            }
        }
        b {

            grid-column: 2/3;
            grid-row: 3/3;
        }
        div {
            grid-column: 1/2;
            grid-row: 1/4;
            position: relative;
            width: 140px;
            height: 140px;

            &::before {
                position: absolute;
                z-index: 0;
                display: block;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                font-weight: bold;
                text-align: center;
                line-height: 140px;
                background-color: #daddde;
                color: #fff;
            }

            img {
                position: relative;
                z-index: 1;
                width: 140px;
                height: 140px;
            }
            label {
                align-items: center;
                margin: 0px 10px 0 0;
                position: relative;

            }
        }
    }
    .type_minimum_image {
        display: grid;
        grid-template-columns: 168px 1fr;
        grid-template-rows: 55px auto 1fr;
        margin-bottom: 24px;

        select {
            grid-column: 2/3;
            grid-row: 1/3;
            border-radius: 2px;
            width: 400px;
        }
        label {
            grid-column: 2/3;
            grid-row: 3/3;
            width: 484px;
            height: 32px;

            input {
                flex: 3;
                line-height: 28px;
                padding: 0 8px;

                &::file-selector-button {
                    display: none;
                }
            }
            b {
                display: inline-block;
                flex: 1;
                padding: 0 8px;
                height: 32px;
                border: 2px solid;
                border-radius: 2px;
                background-color: #fff;
                border: 2px solid #c9cbd3;
                font-weight: normal;
                letter-spacing: 0;
                font-size: 14px;
                text-align: center;
                line-height: 28px;
                margin-left: -1px;
                cursor: pointer;
            }
        }
        b {

            grid-column: 2/3;
            grid-row: 3/3;
        }
        div {
            grid-column: 1/2;
            grid-row: 1/4;
            position: relative;
            width: 140px;
            height: 140px;

            &::before {
                position: absolute;
                z-index: 0;
                display: block;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                font-weight: bold;
                text-align: center;
                line-height: 140px;
                background-color: #daddde;
                color: #fff;
            }

            img {
                position: relative;
                z-index: 1;
                width: 140px;
                height: 140px;
            }
            label {
                align-items: center;
                margin: 0px 10px 0 0;
                position: relative;
                left: 5px;
                bottom: 5px;
            }
        }
    }
    .notes_info {
        font-size: 14px;
        margin: 16px 0 0;
    }

    input[type="file"] {
        height: 100%;
        width: 100%;
        border-radius: 2px;
    }
    input[type="text"],
    select {
        border-radius: 2px;
        min-width: 30em;
    }
    textarea {
        width: 100%;
        height: 30px;
    }
    input[type="checkbox"],
    input[type="radio"] {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        width: 24px;
        height: 24px;
        margin: 0;
        opacity: 0;
        cursor: pointer;
        transform: translate( -50%, -50%);

        /* &::before {
            content: "";
            display: block;
            width: 24px;
            height: 24px;

            background-color: transparent;
            background-attachment: scroll;
            background-repeat: no-repeat;
            background-position: center center;
            transform: translate(0, -50%);
        } */
        & + span{
            display: flex;
            align-items: center;

            &::before {
                content: "";
                display: inline-block;
                width: 24px;
                height: 24px;
                margin-right: 0.5em;
                pointer-events: none;
                /* background: transparent url(${iconCheckboxOff}) scroll no-repeat center center; */
                background-color: transparent;
                background-repeat: no-repeat;
                background-position: center center;
                background-attachment: scroll;
            }
        }

    }
    input[type="checkbox"] {
        /* &::before {
            background-image: url(${iconCheckboxOff});
        }
        &:checked::before {
            background-image: url(${iconCheckboxOn});
        } */
        & + span::before {
            background-image: url(${iconCheckboxOff});
        }
        &:checked + span::before {
            background-image: url(${iconCheckboxOn});
        }
    }
    input[type="radio"] {
        /* &::before {
            background-image: url(${iconRadioOff});
        }
        &:checked::before {
            background-image: url(${iconRadioOn});
        } */
        & + span::before {
            background-image: url(${iconRadioOff});
        }
        &:checked + span::before {
            background-image: url(${iconRadioOn});
        }
    }
`;

const SelectWithIcon = styled("select")`
    appearance: none;
    padding: 4px 1.8em 4px 8px;
    background: transparent url(${iconSelectDown}) right center no-repeat scroll;
`;

interface Props {
    name: string;
    value: ArticleSchemaItem;
    errors?: any;
    register: UseFormRegister<any>;
    // 無理やり渡すのでanyで
    setValue: UseFormSetValue<any>;
    addDuration: any;
    index: number;
}

export function ArticleSchemaItemForm({ name, value, errors, register, setValue, addDuration, index }: Props) {
    const options = Object.entries(value.content.item ?? {});
    const [selected, setSelected] = useState("");
    // サムネイル
    const getThumbnailImage = ():string => {
        if(value.content.value.data && value.content.value.mimetype){
            if(value.content.value.mimetype.includes('image')){
                return value.content.value.data;
            }
            else if(value.content.value.mimetype.includes('video')){
                return '/image/icon_video.png';
            }
            else if(value.content.value.mimetype.includes('audio')){
                return '/image/icon_audio.png';
            }
            else{
                if(value.content.value.type){
                    if(value.content.value.type === 'signage-url' || value.content.value.type === 'signage-text'){
                        return value.content.value.data;
                    }
                }
                return '/image/icon_text.png';
            }
        }
        return '/image/icon_noimage.png';
    }
    const [thumbnailImage, setThumbnailImage] = useState(getThumbnailImage());
    const [contentData, setContentData] = useState(value.content.value?.data) || "";
    const [contentFile, setContentFile] = useState(value.content.value?.filename) || "";
    /** ラジオボタン切り替えイベント */
    const changeValue = (event: React.ChangeEvent<HTMLInputElement>) => changeRadioValue(event);
    const handleMimeChange = (contentValue: Record<string, string>) => {
        setValue(`${name}.content.value`, contentValue);
    };
    const handleMimeChangeImage = (contentValue: Record<string, string>) => {
        setValue(`${name}.content.value`, contentValue);
        setThumbnailImage(contentValue.data);
        setContentData(contentValue.data);
        setContentFile(contentValue.filename);
    };
    const handleMimeChangeVideo = (contentValue: Record<string, string>) => {
        setValue(`${name}.content.value`, contentValue);
        setThumbnailImage('/image/icon_video.png');
        setContentData(contentValue.data);
        setContentFile(contentValue.filename);
    };
    const handleMimeChangeAudio = (contentValue: Record<string, string>) => {
        setValue(`${name}.content.value`, contentValue);
        setThumbnailImage('/image/icon_audio.png');
        setContentData(contentValue.data);
        setContentFile(contentValue.filename);
    };
    const handleMimeChangeFile = (contentValue: Record<string, string>) => {
        setValue(`${name}.content.value`, contentValue);
        setThumbnailImage('/image/icon_text.png');
        setContentData(contentValue.data);
        setContentFile(contentValue.filename);
    };
    const handleDurationChange = (durationValue: string) => {
        setValue(`${name}.content.value.duration`, durationValue);
    };
    const handleImageChange = (contentData: string, contentFile: string) => {
        setValue(`${name}.content.value.data`, contentData);
        setValue(`${name}.content.value.filename`, contentFile);
        setThumbnailImage(contentData);
        setContentData(contentData);
        setContentFile(contentFile);
    };

    const getRadioSignage = ():Radio[] => {
        if(!('radioItem' in value.content) || !value.content.radioItem){
            return radioSignage;
        }
        let radioItem = value.content.radioItem;
        let items:Radio[] = [];
        radioSignage.forEach((radio) => {
            if (radio.value === 'video') {
                if (!('signageVideo' in radioItem) || radioItem.signageVideo) {
                    items.push(radio);
                }
            }
            if (radio.value === 'image') {
                if (!('signageImage' in radioItem) || radioItem.signageImage) {
                    items.push(radio);
                }
            }
            if (radio.value === 'url') {
                if (!('signageUrl' in radioItem) || radioItem.signageUrl) {
                    items.push(radio);
                }
            }
            if (radio.value === 'text') {
                if (!('signageText' in radioItem) || radioItem.signageText) {
                    items.push(radio);
                }
            }
            if (radio.value === 'clear') {
                items.push(radio);
            }
        })
        return items;
    }

    const changeRadioValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.value === "clear" && value.content.value?.data){
            if( window.confirm("コンテンツを削除してよろしいですか？") ) {
                value.content.value.data = "";
                if (value.content.value?.filename) value.content.value.filename = "";
                if (value.content.value?.mimetype) value.content.value.mimetype = "";
                if (value.content.value?.duration) value.content.value.duration = 0.0;
                addDuration(0, index);
                handleMimeChange(value.content.value);
                setSelected(event.target.value);
            }
        }
        else if(event.target.value === "clear" && value.content.value?.url){
            if( window.confirm("URLを削除してよろしいですか？") ) {
                value.content.value.url = "";
                if (value.content.value?.filename) value.content.value.filename = "";
                if (value.content.value?.mimetype) value.content.value.mimetype = "";
                if (value.content.value?.duration) value.content.value.duration = 0.0;
                addDuration(0, index);
                handleMimeChange(value.content.value);
                setSelected(event.target.value);
            }
        }
        else{
            if (value.content.value?.filename) value.content.value.filename = "";
            if (value.content.value?.mimetype) value.content.value.mimetype = "";
            if (value.content.value?.duration) value.content.value.duration = 0.0;
            addDuration(0, index);
            handleMimeChange(value.content.value);
            setSelected(event.target.value);
        }
    }

    switch (value.content.type) {
        case "file":
            const handleChange = (value: Record<string, string>) => {
                setValue(`${name}.content.value`, value);
            };
            if(!value.content.value.mimetype){
                return (
                    <SchemaItem>
                        <div className="title"><label>{value.title}</label></div>
                        {value.content.info && (
                            <div className="info"><label style={{whiteSpace: 'pre-line'}}>{value.content.info}</label></div>
                        )}
                        <div className="type_file">
                            <label>
                                <ArticleSchemaItemFileContentControl onChange={handleChange} content={value.content} />
                                <b>ファイルを選択</b>
                            </label>
                            <div>
                                <img src='/image/icon_noimage.png' alt="" />
                            </div>
                            {errors?.content?.value?.data?.message}
                            {errors?.content?.value?.filename?.message}
                            {errors?.content?.value?.mimetype?.message}
                        </div>
                    </SchemaItem>
                );
            }
            else{
                if(value.content.value.mimetype.includes('image')){
                    return (
                        <SchemaItem>
                            <div className="title"><label>{value.title}</label></div>
                            {value.content.info && (
                                <div className="info"><label style={{whiteSpace: 'pre-line'}}>{value.content.info}</label></div>
                            )}
                            <div className="type_file_image">
                                <label>
                                    <ArticleSchemaItemFileContentControl onChange={handleMimeChangeImage} content={value.content} />
                                    <b>ファイルを変更</b>
                                </label>
                                <div>
                                    <a href={contentData}><img src={thumbnailImage} alt={contentFile} />
                                        <label>{contentFile}</label></a>
                                </div>
                                {errors?.content?.value?.data?.message}
                                {errors?.content?.value?.filename?.message}
                                {errors?.content?.value?.mimetype?.message}
                            </div>
                        </SchemaItem>
                    );
                }
                else if(value.content.value.mimetype.includes('video')){
                    return (
                        <SchemaItem>
                            <div className="title"><label>{value.title}</label></div>
                            {value.content.info && (
                                <div className="info"><label style={{whiteSpace: 'pre-line'}}>{value.content.info}</label></div>
                            )}
                            <div className="type_file_video">
                                <label>
                                    <ArticleSchemaItemFileContentControl onChange={handleMimeChangeVideo} content={value.content} />
                                    <b>ファイルを変更</b>
                                </label>
                                <div>
                                    <a href={contentData}><img src={thumbnailImage} alt={contentFile} />
                                        <label>{contentFile}</label></a>
                                </div>
                                {errors?.content?.value?.data?.message}
                                {errors?.content?.value?.filename?.message}
                                {errors?.content?.value?.mimetype?.message}
                            </div>
                        </SchemaItem>
                    );
                }
                else if(value.content.value.mimetype.includes('audio')){
                    return (
                        <SchemaItem>
                            <div className="title"><label>{value.title}</label></div>
                            {value.content.info && (
                                <div className="info"><label style={{whiteSpace: 'pre-line'}}>{value.content.info}</label></div>
                            )}
                            <div className="type_file_video">
                                <label>
                                    <ArticleSchemaItemFileContentControl onChange={handleMimeChangeAudio} content={value.content} />
                                    <b>ファイルを変更</b>
                                </label>
                                <div>
                                    <a href={contentData}><img src={thumbnailImage} alt={contentFile} />
                                        <label>{contentFile}</label></a>
                                </div>
                                {errors?.content?.value?.data?.message}
                                {errors?.content?.value?.filename?.message}
                                {errors?.content?.value?.mimetype?.message}
                            </div>
                        </SchemaItem>
                    );
                }
                else{
                    return (
                        <SchemaItem>
                            <div className="title"><label>{value.title}</label></div>
                            {value.content.info && (
                                <div className="info"><label style={{whiteSpace: 'pre-line'}}>{value.content.info}</label></div>
                            )}
                            <div className="type_file_file">
                                <label>
                                    <ArticleSchemaItemFileContentControl onChange={handleMimeChangeFile} content={value.content} />
                                    <b>ファイルを変更</b>
                                </label>
                                <div>
                                    <a href={contentData}><img src={thumbnailImage} alt={contentFile} />
                                        <label>{contentFile}</label></a>
                                </div>
                                {errors?.content?.value?.data?.message}
                                {errors?.content?.value?.filename?.message}
                                {errors?.content?.value?.mimetype?.message}
                            </div>
                        </SchemaItem>
                    );
                }
            }

        case "signage":
            if (selected === "") {
                if (value.content.value?.type === "signage-video"){
                    setSelected("video");
                }
                else if (value.content.value?.type === "signage-image"){
                    setSelected("image");
                }
                else if (value.content.value?.type === "signage-url"){
                    setSelected("url");
                }
                else if (value.content.value?.type === "signage-text"){
                    setSelected("text");
                }
            }

            const radioItems = getRadioSignage();

            return (
                <SchemaItem>
                    <div className="title"><label>{value.title}</label></div>
                    {value.content.info && (
                        <div className="info"><label style={{whiteSpace: 'pre-line'}}>{value.content.info}</label></div>
                    )}
                    <div className="type_signage_radio">
                        {radioItems.map((radio) => (
                            <label key={radio.value}>
                                <input type="radio" value={radio.value} checked={radio.value === selected} onChange={changeValue} />
                                <span>
                                    {radio.label}
                                </span>
                            </label>
                        ))}
                    </div>
                    {selected === "video" && (
                        <SchemaItem>
                            <div className="type_signage_video">
                                <ArticleSignageVideoContentControl 
                                    onChange={handleMimeChangeVideo}
                                    addDuration={addDuration}
                                    index={index}
                                    duration={value.content.value.duration || 0.0}
                                    content={value.content}
                                />
                                <div>
                                    <a href={contentData}><img src={thumbnailImage} alt={contentFile} />
                                        <label>{contentFile}</label></a>
                                </div>
                                {errors?.content?.value?.data?.message}
                                {errors?.content?.value?.filename?.message}
                                {errors?.content?.value?.mimetype?.message}
                            </div>
                        </SchemaItem>
                    )}
                    {selected === "image" && (
                        <SchemaItem>
                            <div className="type_signage_image">
                                <ArticleSignageImageContentControl 
                                    onChange={handleMimeChangeImage}
                                    addDuration={addDuration}
                                    changeDuration={handleDurationChange}
                                    index={index}
                                    duration={value.content.value.duration || 0.0}
                                    content={value.content}
                                />
                                <div>
                                    <a href={contentData}><img src={thumbnailImage} alt={contentFile} />
                                        <label>{contentFile}</label></a>
                                </div>
                                {errors?.content?.value?.data?.message}
                                {errors?.content?.value?.filename?.message}
                                {errors?.content?.value?.mimetype?.message}
                            </div>
                        </SchemaItem>
                    )}
                    {selected === "url" && (
                        <SchemaItem>
                            <div className="type_signage_url">
                                <ArticleSignageURLContentControl
                                    onChange={handleMimeChangeImage}
                                    addDuration={addDuration}
                                    changeDuration={handleDurationChange}
                                    changeImage={handleImageChange}
                                    index={index}
                                    duration={value.content.value.duration || 0.0}
                                    imageName={contentFile}
                                    imageData={contentData}
                                    url={value.content.value.url || ""}
                                    content={value.content}
                                />
                                <div>
                                    <a href={contentData}><img src={thumbnailImage} alt={contentFile} />
                                        <label>{contentFile}</label></a>
                                </div>
                                {errors?.content?.value?.data?.message}
                                {errors?.content?.value?.filename?.message}
                                {errors?.content?.value?.mimetype?.message}
                            </div>
                        </SchemaItem>
                    )}
                    {selected === "text" && (
                        <SchemaItem>
                            <div className="type_signage_text">
                                <ArticleSignageTextContentControl
                                    onChange={handleMimeChangeImage}
                                    addDuration={addDuration}
                                    changeDuration={handleDurationChange}
                                    changeImage={handleImageChange}
                                    index={index}
                                    duration={value.content.value.duration || 0.0}
                                    imageName={value.content.value.filename}
                                    imageData={value.content.value.data}
                                    text={value.content.value.text || ""}
                                    content={value.content}
                                />
                                <div>
                                    <a href={contentData}><img src={thumbnailImage} alt={contentFile} />
                                        <label>{contentFile}</label></a>
                                </div>
                                {errors?.content?.value?.data?.message}
                                {errors?.content?.value?.filename?.message}
                                {errors?.content?.value?.mimetype?.message}
                            </div>
                        </SchemaItem>
                    )}
                </SchemaItem>
            );

        case "minimum-schema":
            if(selected === ""){
                if(value.content.value?.mimetype?.includes("video/")){
                    setSelected("video");
                }
                else if(value.content.value?.mimetype?.includes("image/")){
                    setSelected("image");
                }
            }

            return (
                <SchemaItem>
                    <div className="type_minimum_radio">
                        {radioMinimum.map((radio) => (
                            <label key={radio.value}>
                                <input type="radio" value={radio.value} checked={radio.value === selected} onChange={changeValue} />
                                <span>
                                    {radio.label}
                                </span>
                            </label>
                        ))}
                    </div>
                    <ContentError errors={errors} />
                    { selected === "video" && (
                        <SchemaItem>
                            <div className="type_minimum_video">
                                <ArticleSchemaVideoContentControl onChange={handleMimeChangeVideo} 
                                                                  addDuration={addDuration}
                                                                  index={index}
                                                                  maxTime={value.content.maxTime || 0}
                                                                  duration={value.content.value.duration || 0.0}
                                                                  content={value.content}
                                                                  />
                                <div>
                                    <a href={contentData}><img src={thumbnailImage} alt={contentFile} />
                                        <label>{contentFile}</label></a>
                                </div>
                                {errors?.content?.value?.data?.message}
                                {errors?.content?.value?.filename?.message}
                                {errors?.content?.value?.mimetype?.message}
                            </div>
                        </SchemaItem>
                    )}
                    { selected === "image" && (
                        <SchemaItem>
                            <div className="type_minimum_image">
                                <ArticleSchemaImageContentControl onChange={handleMimeChangeImage} 
                                                                  addDuration={addDuration}
                                                                  changeDuration={handleDurationChange}
                                                                  index={index}
                                                                  maxTime={value.content.maxTime || 0}
                                                                  duration={value.content.value.duration || 0.0}
                                                                  content={value.content}
                                                                  />
                                <div>
                                    <a href={contentData}><img src={thumbnailImage} alt={contentFile} />
                                        <label>{contentFile}</label></a>
                                </div>
                                {errors?.content?.value?.data?.message}
                                {errors?.content?.value?.filename?.message}
                                {errors?.content?.value?.mimetype?.message}
                            </div>
                        </SchemaItem>
                    )}
                </SchemaItem>
            );

        case "text":
            return (
            <SchemaItem>
                <div className="title"><label>{value.title}</label></div>
                {value.content.info && (
                    <div className="info"><label style={{whiteSpace: 'pre-line'}}>{value.content.info}</label></div>
                )}
                <div className="type_text">
                    <input type="text" {...register(`${name}.content.value.0`)} autoComplete="off" />
                </div>
                <ContentError errors={errors} />
            </SchemaItem>
            );
        case "textarea":
            return (
            <SchemaItem>
                <div className="title"><label>{value.title}</label></div>
                {value.content.info && (
                    <div className="info"><label style={{whiteSpace: 'pre-line'}}>{value.content.info}</label></div>
                )}
                <div className="type_textarea">
                    <textarea {...register(`${name}.content.value.0`)}></textarea>
                </div>
                <ContentError errors={errors} />
            </SchemaItem>
            );
        case "checkbox":
            return (
            <SchemaItem>
                <div className="title"><label>{value.title}</label></div>
                {value.content.info && (
                    <div className="info"><label style={{whiteSpace: 'pre-line'}}>{value.content.info}</label></div>
                )}
                <div className="type_checkbox">
                    {options.map((label, value) => (
                        <label key={value}>
                            <input type="checkbox" value={value} {...register(`${name}.content.value`)} />
                            <span>
                                {label[0]}
                            </span>
                        </label>
                        
                    ))}
                </div>
                <ContentError errors={errors} />
            </SchemaItem>
            );
        case "radio":
            return (
            <SchemaItem>
                <div className="title"><label>{value.title}</label></div>
                {value.content.info && (
                    <div className="info"><label style={{whiteSpace: 'pre-line'}}>{value.content.info}</label></div>
                )}
                <div className="type_radio">
                    {options.map((label, value) => (
                        <label key={value}>
                            <input type="radio" value={value} {...register(`${name}.content.value.0`)} />
                            <span>
                                {label}
                            </span>
                        </label>
                    ))}
                </div>
                <ContentError errors={errors} />
            </SchemaItem>
            );
        case "select":
            return (
            <SchemaItem>
                <div className="title"><label>{value.title}</label></div>
                {value.content.info && (
                    <div className="info"><label style={{whiteSpace: 'pre-line'}}>{value.content.info}</label></div>
                )}
                <div className="type_select">
                    <SelectWithIcon {...register(`${name}.content.value.0`)}>
                        <option>未選択</option>
                        {options.map((label, value) => (
                            <option key={value} value={value}>
                                {label}
                            </option>
                        ))}
                    </SelectWithIcon>
                </div>
                <ContentError errors={errors} />
            </SchemaItem>
            );
    }
    return <></>;
}

function ContentError({ errors }: { errors?: any }) {
    return (
        <>
            {errors?.content?.value?.map((e: any, i: number) => (
                <span key={i}>{e.message}</span>
            ))}
        </>
    );
}
